<template>
  <div class="card result-card shadow">
    <div class="card-position">
      <p>{{ result.position }}</p>
    </div>
    <div class="card-header">
      <p>
        <span class="card-score">{{ result.score }}%</span> match
      </p>
      <h2 class="card-title" :class="!index ?  '' : 'blur-txt'">{{ engine.title }}</h2>
      <p :class="!index ?  '' : 'blur-txt'">{{ engine.sub }}</p>
    </div>
    <div class="card-body">
      <p class="card-text" :class="!index ?  '' : 'blur-txt'">
        {{ engine.description }}
      </p>
      <p v-if="engine.warning" :class="!index ?  '' : 'blur-txt'">
        <i class="bi bi-exclamation-circle-fill"></i>{{ engine.warning }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResultItem',
  props: ['engine', 'result', 'index'],
};
</script>

<style lang="scss">
.blur-txt {
  filter: blur(6px)
}
</style>
