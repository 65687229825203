<template>
  <div class="optin-card card shadow">
    <h2>{{ $t('optin.title') }}</h2>
    <p>{{ $t('optin.sub') }}</p>
    <p class="small">{{ $t('optin.warning') }}</p>
    <form @submit.prevent="handleSubmit">
      <div class="mb-3">
        <p class="form-err">{{ emailErr }}</p>
        <el-input
          type="text"
          v-model="email"
          :placeholder="$t('survey.emailPlaceholder')"
          @blur="formatEmail"
        />
      </div>
      <div class="form-check">
        <p class="form-err" style="margin-bottom: -0.5rem">{{ checkboxErr }}</p>
        <input
          class="form-check-input"
          type="checkbox"
          v-model="checked"
          @change="handleChange"
        />
        <label class="form-check-label">
          {{ $t('optin.checkbox') }}
        </label>
      </div>
      <div class="btn-center mt-3">
        <app-button>{{ $t('survey.results') }}</app-button>
      </div>
    </form>
    <share-preview />
  </div>
</template>

<script>
import SharePreview from '@/components/results/SharePreview';
import UserService from '@/utils/services/UserService';

export default {
  name: 'Optin',
  components: {
    SharePreview,
  },
  data() {
    return {
      email: '',
      checked: false,
      userId: '',
      userZip: null,
      checkboxErr: null,
      emailErr: null,
    };
  },
  created() {
    const localId = localStorage.getItem('user_id');

    if (localId) this.userId = localId;
    else this.userId = this.$route.params.id;
  },
  methods: {
    handleSubmit() {
      if (!this.checked) {
        this.checkboxErr = this.$t('optin.checkboxErr');
        return;
      }

      if (this.email === '' || !this.email) {
        this.emailErr = this.$t('optin.emailErr');
        return;
      }

      if (!this.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        this.emailErr = this.$t('optin.emailErr');
        return;
      }

      const user = {
        id: this.userId,
        email: this.email,
        lang: this.$i18n.locale,
        optin: this.checked,
        zip: localStorage.getItem('user_zip'),
      };

      UserService.createUser(user)
        .then(() => {
          this.$router.push({ name: 'Success' });
        });
    },
    formatEmail() {
      this.email = this.email.toLowerCase();
    }
  },
};
</script>

<style lang="scss">
.social-share {
  display: block;
  justify-content: center;
  align-items: center;
}
</style>
