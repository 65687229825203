<template>
  <template v-if="results.length">
    <div
      class="col-12 col-lg-6 results-preview"
      v-for="(result, index) in results"
      :key="result.position"
      :class="`column-${index + 1}`"
    >
      <result-item :result="result" :engine="result.engine" :index="index" />
    </div>
  </template>
  <app-error v-if="showError" />
  <div class="col-12 col-lg-6" v-if="loading">
    <app-card>
      <template #title
        ><el-skeleton :rows="0" :loading="loading" animated
      /></template>
      <template #text
        ><el-skeleton :rows="6" :loading="loading" animated
      /></template>
    </app-card>
  </div>
</template>

<script>
import ResultService from '@/utils/services/ResultService';
import ResultItem from '@/components/results/ResultItem';

export default {
  name: 'ResultPreview',
  components: {
    ResultItem,
  },
  emits: ['hasError'],
  data() {
    return {
      results: [],
      dbResults: [],
      loading: true,
      showError: false,
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },
  async created() {
    const resultsFromStorage = localStorage.getItem('results');

    if (resultsFromStorage) {
      this.dbResults = JSON.parse(resultsFromStorage);
      this.filterResults();
      return;
    }

    try {
      const res = await ResultService.getResults(this.$route.params.id);

      this.dbResults = res.results;
      this.env_percentage = ResultService.getEnvPercentage(
        this.lang,
        res.results[0].envPercentage
      );
      localStorage.setItem('env_percentage', this.env_percentage);
      this.filterResults();
    } catch (_err) {
      this.showError = true;
      this.loading = false;
    }
  },
  methods: {
    filterResults() {
      this.results = ResultService.filterResults(this.lang, this.dbResults);
      const { loading, error } = ResultService.validateResults(this.results);

      if (error) this.$emit('hasError');

      this.loading = loading;
      this.showError = error;
    },
  },
};
</script>

<style lang="scss">
.results-preview {
  margin-bottom: 4rem;
  &.column-1 {
    order: 1
  }
  &.column-2 {
    order: 3
  }
  &.column-3 {
    order: 4
  }
}

.success-card {
  .card-header {
    background-color: $lightest-green;
    h2 {
      font-size: 24px;
    }
  }
}

.error-card {
  margin: 0 auto;
}

.results-preview {
  justify-content: center;
  display: flex;
}
</style>
