<template>
  <div class="take-test card shadow">
    <h2>{{ $t('optin.alternate.title') }}</h2>
    <p>{{ $t('optin.alternate.sub') }}</p>
    <div class="btn-center mt-3">
      <localized-link :to="{ name: 'Test' }">
        <app-button>{{ $t('optin.alternate.cta') }}</app-button>
      </localized-link>
    </div>
    <share-preview />
  </div>
</template>

<script>
import SharePreview from '@/components/results/SharePreview';
export default {
  name: 'TakeTest',
  components: {
    SharePreview,
  },
};
</script>

<style lang="scss">
.take-test {
  margin-top: 3rem;
  padding: 1.5rem 1rem;
}
</style>
