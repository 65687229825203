<template>
  <div class="row mt-4">
    <p class="mb-0 show-share text-center" @click="showShare = !showShare">
      <i class="bi bi-share-fill"></i>{{ $t('survey.share')}}
    </p>
    <div class="social-shares" v-if="showShare">
      <ShareNetwork
        v-for="network in networks"
        :key="network.network"
        :network="network.network"
        :url="sharing.url"
        :title="$t('social.title')"
        :description="$t('social.description')"
        :quote="sharing.quote"
        :hashtags="$t('social.keywords')"
        :twitterUser="sharing.twitterUser"
        :class="network.icon"
        :style="{ color: network.color }"
      >
      </ShareNetwork>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SharePreview',
  data() {
    return {
      showShare: false,
      sharing: {
        url: 'https://fuelguide.be',
        quote: '',
        twitterUser: 'WeLOOP_',
      },
      networks: [
        {
          network: 'email',
          name: 'Email',
          icon: 'bi bi-envelope-fill',
          color: '#005E75',
        },
        {
          network: 'facebook',
          name: 'Facebook',
          icon: 'bi bi-facebook',
          color: '#005E75',
        },
        {
          network: 'linkedin',
          name: 'LinkedIn',
          icon: 'bi bi-linkedin',
          color: '#005E75',
        },
        {
          network: 'twitter',
          name: 'Twitter',
          icon: 'bi bi-twitter',
          color: '#005E75',
        },
      ],
    };
  },
  created() {
    this.sharing.url = window.location.href;
  },
};
</script>

<style lang="scss">
.show-share {
  cursor: pointer;
  color: $dark-bg;
  text-decoration: underline;
  text-underline-offset: 5px;
  .bi-share-fill {
    padding-right: 1rem;
  }
}
.social-shares {
  display: flex;
  justify-content: center;
  padding-top: 1rem!important;
  a {
    margin-right: 2rem !important;
    font-size: 1.5rem;
    &:last-child {
      margin-right: 0 !important;
    }
  }
}
</style>
